const downloader = (url) => {
  if (typeof url !== "string") {
    console.log("URL must be a string")
    return
  }
  let a = document.createElement('a')
  a.href = url
  a.download = url.split('/').pop()
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export default downloader
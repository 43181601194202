import React, { useState, useEffect, useRef } from 'react'
import { Button, Typography } from '@material-ui/core';
import axios from 'axios'

import Phaser from 'phaser'
import { IonPhaser } from '@ion-phaser/react'
import VirtualJoystickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin.js';
import GameOver from './gameover'
import HomeScreen from './home'
import music from '../../assets/game_music.mp3'
import bling from '../../assets/coin_sound.ogg'
import puft from '../../assets/explosion_sound.ogg'
import fire from '../../assets/fire.png'
import smoke from '../../assets/smoke.png'
import tito from '../../assets/plane_player.png'
import jtbase from '../../assets/joystick_base.png'
import jtthumb from '../../assets/joystick_thumb.png'
import star from '../../assets/star.png'
import sky from '../../assets/sky.jpg'
import painel from '../../assets/console.jpg'
import bomb from '../../assets/bomb.png'
import brick from '../../assets/brick.png'
import a from '../../assets/alphabet/a.png'
import b from '../../assets/alphabet/b.png'
import c from '../../assets/alphabet/c.png'
import d from '../../assets/alphabet/d.png'
import f from '../../assets/alphabet/f.png'
import g from '../../assets/alphabet/g.png'
import h from '../../assets/alphabet/h.png'
import j from '../../assets/alphabet/j.png'
import i from '../../assets/alphabet/i.png'
import k from '../../assets/alphabet/k.png'
import m from '../../assets/alphabet/m.png'
import o from '../../assets/alphabet/o.png'
import q from '../../assets/alphabet/q.png'
import s from '../../assets/alphabet/s.png'
import t from '../../assets/alphabet/t.png'
import u from '../../assets/alphabet/u.png'
import v from '../../assets/alphabet/v.png'
import w from '../../assets/alphabet/w.png'
import x from '../../assets/alphabet/x.png'
import y from '../../assets/alphabet/y.png'
import n from '../../assets/alphabet/n.png'
import p from '../../assets/alphabet/p.png'
import l from '../../assets/alphabet/l.png'
import e from '../../assets/alphabet/e.png'
import r from '../../assets/alphabet/r.png'
import z from '../../assets/alphabet/z.png'


String.prototype.replaceAt = function(index, replacement) {
  return this.substr(0, index) + replacement + this.substr(index + replacement.length);
}

function shuffle(array) {
  var currentIndex = array.length,  randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}

let target = 0;
const SPEED = 215;
const ROTATION_SPEED = 6 * Math.PI;

class MainScene extends Phaser.Scene {

  constructor() {
    super('MainScene');
  }

  init () {
    this.cameras.main.setBackgroundColor('#24252A')
    this.words = ["hi"]
    this.current_word_order = 0
    this.current_word = this.words[this.current_word_order]
    this.collected_letters = []
    this.collected_letters_count = 0
    this.lives = 3
    this.score = 0
    axios.get(`${window.conf.bardEndpoint}/words`)
    .catch(e => console.log(e))
    .then(res => {
      if (res.data.length < 1) {
        return
      }
      let words = shuffle(res.data.map(item => item.word_text.replace(" ", "")))
      this.words.push(...words)
    })
  }

  preload () {
    this.load.audio('music', music)
    this.load.audio('bling', bling)
    this.load.audio('puft', puft)
    this.load.image('jtbase', jtbase);
    this.load.image('jtthumb', jtthumb);
    this.load.image('fire', fire);
    this.load.image('smoke', smoke);
    this.load.image('tito', tito);
    this.load.image('star', star);
    this.load.image('sky', sky);
    this.load.image('painel', painel);
    this.load.image('bomb', bomb);
    this.load.image('brick', brick);
    this.load.image('a', a);
    this.load.image('b', b);
    this.load.image('c', c);
    this.load.image('d', d);
    this.load.image('e', e);
    this.load.image('f', f);
    this.load.image('g', g);
    this.load.image('h', h);
    this.load.image('i', i);
    this.load.image('j', j);
    this.load.image('k', k);
    this.load.image('l', l);
    this.load.image('m', m);
    this.load.image('n', n);
    this.load.image('o', o);
    this.load.image('p', p);
    this.load.image('q', q);
    this.load.image('r', r);
    this.load.image('s', s);
    this.load.image('t', t);
    this.load.image('u', u);
    this.load.image('v', v);
    this.load.image('w', w);
    this.load.image('x', x);
    this.load.image('y', y);
    this.load.image('z', z);

  }

  create () {
    this.add.image(400, 600, 'sky');
    this.music = this.sound.add('music')
    this.bling = this.sound.add('bling')
    this.puft = this.sound.add('puft')
    this.music.loop = true
    this.music.play()
    this.brect = this.add.image(400, 1100, 'painel');
    const jbase = this.add.image(0, 0, 'jtbase')
    const jthumb = this.add.image(0, 0, 'jtthumb')
    jthumb.setScale(0.7)

    const joyConfig = {
      x: 630,
      y: 1040,
      radius: 87,
      base: jbase,
      thumb: jthumb
    }

    this.joyStick = this.plugins.get('rexVirtualJoystick').add(this, joyConfig) ;

    const customBounds = new Phaser.Geom.Rectangle(0, 0, 800, 1000);

    this.scoreText = this.add.text(350, 1110, 'Points: ' + this.score, {fontSize: '50px', fill: '#6ffc03', fontFamily: 'Sassoon'})
    this.livesText = this.add.text(350, 1050, '❤: ' + this.lives, {fontSize: '50px', fill: '#F42828', fontFamily: 'Sassoon'})
    this.target_word = this.add.text(14, 1000, this.current_word,
      { fontSize: '60px',
        fill: '#FFFF00',
        fontFamily: "Sassoon",
        padding: 16,
      });
    this.completing_word = "-".repeat(this.current_word.length)
    this.completed_word = this.add.text(14, 1096, this.completing_word ,
      { fontSize: '60px',
        fill: '#d2ffb5',
        fontFamily: "Sassoon",
        padding: 16
      });

    this.fireEmitter = this.add.particles('fire').createEmitter({
      // x: 400,
      // y: 300,
      speed: { min: -200, max: 300 },
      angle: { min: 0, max: 360 },
      scale: { start: 0.5, end: 0 },
      // blendMode: 'SCREEN',
      // active: false,
      on: false,
      lifespan: 600,
      gravityY: 50
    });

    this.smokeEmitter = this.add.particles('smoke').createEmitter({
      // x: 400,
      // y: 300,
      speed: { min: -200, max: 300 },
      angle: { min: 0, max: 360 },
      scale: { start: 0.5, end: 0 },
      // blendMode: 'SCREEN',
      // active: false,
      on: false,
      lifespan: 600,
      gravityY: -1500
    });

    this.titao = this.physics.add.sprite(70, 70, 'tito').setVelocity(SPEED, 0)
    this.titao.setScale(0.75);
    this.titao.setCollideWorldBounds(true)
    this.titao.body.customBoundsRectangle = customBounds

    this.platforms = this.physics.add.staticGroup();
    this.platforms.create(400, 968, 'brick').setScale(0.2).refreshBody();

    this.platforms.create(600, 400, 'brick').setScale(0.2).refreshBody();
    this.platforms.create(50, 250, 'brick').setScale(0.2).refreshBody();
    this.platforms.create(750, 220, 'brick').setScale(0.2).refreshBody();

    this.stars = this.physics.add.group({
      key: 'star',
      frameQuantity: 6,
      setScale: {x: 0.1, y: 0.1},
      collideWorldBounds: true,
      customBoundsRectangle: customBounds,
      bounceX: 1,
      bounceY: 1,
    });

    this.bombs = this.physics.add.group({
      key: 'bomb',
      frameQuantity: 4,
      setScale: {x: 0.085, y: 0.085},
      collideWorldBounds: true,
      customBoundsRectangle: customBounds,
      bounceX: 1,
      bounceY: 1,
    });

    this.letters = this.physics.add.group()
    this.letters.createMultiple({
      key: [...this.current_word],
      setScale: {x: 0.5, y: 0.5},
    })

    this.letters.children.iterate(function(child) {
      child.body.setVelocityX(Phaser.Math.FloatBetween(-1, 1) * 100)
      child.body.setVelocityY(Phaser.Math.FloatBetween(-1, 1) * 100)
      child.setCollideWorldBounds(true)
      child.body.setBoundsRectangle(customBounds)
      child.setBounce(1)
    })

    this.stars.children.iterate(function(child) {
      child.body.setVelocityX(Phaser.Math.FloatBetween(-1, 1) * 100)
      child.body.setVelocityY(Phaser.Math.FloatBetween(-1, 1) * 100)
    })

    this.bombs.children.iterate(function(child) {
      child.body.setVelocityX(Phaser.Math.FloatBetween(-1, 1) * 100)
      child.body.setVelocityY(Phaser.Math.FloatBetween(-1, 1) * 100)
    })

    const rect = new Phaser.Geom.Rectangle(0, 100, 800, 900);
    
    Phaser.Actions.RandomRectangle(this.stars.getChildren(), rect);
    Phaser.Actions.RandomRectangle(this.bombs.getChildren(), rect);
    Phaser.Actions.RandomRectangle(this.letters.getChildren(), rect);

    const collectStar = (titao, star) => {
      this.bling.play()
      star.disableBody(true, true);
      this.score = this.score + 5
    }

    const collectLetter = (titao, letter) => {
      let l = letter.texture.key
      let count = this.collected_letters_count
      if (l === this.current_word[count]) {
        this.bling.play()
        this.score = this.score + 15
        this.collected_letters.push(letter.texture.key)
        this.collected_letters_count++
        let newWord = this.completed_word.text.split("").join("")
        newWord = newWord.replaceAt(count, l )
        this.completed_word.setText(newWord)
        letter.disableBody(true, true);
        if (this.collected_letters_count === this.current_word.length) {
          this.current_word = this.words[this.current_word_order]
          this.letters.clear()
          let keys = this.words[this.current_word_order + 1].split("")

          this.stars.children.iterate(function (child) {
            child.enableBody(true, child.x, child.y, true, true);
            child.body.setVelocityX(Phaser.Math.FloatBetween(-1, 1) * 100)
            child.body.setVelocityY(Phaser.Math.FloatBetween(-1, 1) * 100)
          });

          if (this.score > 1000 && this.bombs.children.size === 4) {
            const nBomb = this.physics.add.sprite(600, 600, 'bomb').setScale(0.085)
            this.bombs.add(nBomb)
          } else if (this.score > 2000 && this.bombs.children.size === 5) {
            const nBomb = this.physics.add.sprite(600, 600, 'bomb').setScale(0.085)
            this.bombs.add(nBomb)
          }

          this.bombs.children.iterate(function (child) {
            child.enableBody(true, child.x, child.y, true, true);
            child.body.setVelocityX(Phaser.Math.FloatBetween(-1, 1) * 100)
            child.body.setVelocityY(Phaser.Math.FloatBetween(-1, 1) * 100)
          });

          this.letters.createMultiple({
            key: keys,
            setScale: {x: 0.5, y: 0.5},
            bounceX: 1,
            bounceY: 1,
            collideWorldBounds: true
          })
          this.letters.children.iterate(function(child) {
            child.body.setVelocityX(Phaser.Math.FloatBetween(-1, 1) * 100)
            child.body.setVelocityY(Phaser.Math.FloatBetween(-1, 1) * 100)
            child.setCollideWorldBounds(true)
            child.body.setBoundsRectangle(customBounds)
            child.setBounce(1)
          })
          Phaser.Actions.RandomRectangle(this.letters.getChildren(), rect);
          this.current_word_order++
          resetLetters()

          if (this.lives < 3) {
            this.lives  = this.lives + 1
            this.livesText.setText('❤: ' + (this.lives))
          }
        }
      } else {
        // console.log("errado")
      }
    }

    const loseLife = (titao, bomb) => {
      this.fireEmitter.resume()
      this.puft.play()
      // this.fireEmitter.setPosition(bomb.x, bomb.y)
      this.fireEmitter.explode(30, bomb.x, bomb.y)
      bomb.disableBody(true, true)
      this.livesText.setText('❤: ' + (this.lives - 1))
      this.lives = this.lives - 1
      if (this.lives === 0) {
        this.music.stop()
        this.scene.stop()
        this.scene.switch('GameOver')
      }
    }

    const resetLetters = () => {
      this.collected_letters = []
      this.collected_letters_count = 0
      this.completing_word = "-".repeat(this.current_word.length)
      this.completed_word.setText(this.completing_word)
    }

    this.physics.add.overlap(this.titao, this.stars, collectStar, null, this);
    this.physics.add.collider(this.titao, this.platforms);
    this.physics.add.collider(this.titao, this.letters, collectLetter, null, this);
    this.physics.add.collider(this.titao, this.bombs, loseLife, null, this);

  }

  update (time, delta) {
    this.current_word = this.words[this.current_word_order]
    this.target_word.setText(this.current_word)
    this.scoreText.setText(this.score)

    // this.titao.rotation = Phaser.Math.Angle.RotateTo(
    //   this.titao.rotation,
    //   target,
    //   ROTATION_SPEED * 0.001 * delta
    // );

    if (this.joyStick.rotation !== 0) {
      this.titao.rotation = Phaser.Math.Angle.RotateTo(
        this.titao.rotation,
        this.joyStick.rotation,
        ROTATION_SPEED * 0.001 * delta
      );

      const vx = Math.cos(this.joyStick.rotation) * SPEED
      const vy = Math.sin(this.joyStick.rotation) * SPEED
      this.titao.setVelocity(vx, vy)
    }

    if (this.lives === 1) {
      this.smokeEmitter.start()
      this.smokeEmitter.startFollow(this.titao)
    } else if(this.smokeEmitter.on) {
      this.smokeEmitter.stop()
    }
  }
}

const gameConfig = {
  // width: 800,
  // height: 1200,
  type: Phaser.AUTO,
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    width: 800,
    height: 1200,
    parent: 'phaser-parent'
  },
  plugins: {
    global: [{
      key: 'rexVirtualJoystick',
      plugin: VirtualJoystickPlugin,
      start: true
    }]
  },
  render: {
    antialias: true,
    pixelArt: false,
    // roundPixels: true
  },
  physics: {
    default: 'arcade',
    arcade: {
      // gravity: { y: 400 },
      debug: false
    }
  },
  scene: [HomeScreen, MainScene, GameOver]
};

export default function Game () {
  const gameRef = useRef(null)
  const [game, setGame] = useState()
  const [words, setWords] = useState([])
  const [initialize, setInitialize] = useState(true)

  const destroy = () => {
    if (gameRef.current) gameRef.current.destroy()
    setInitialize(false)
    setGame(undefined)
  }

  useEffect(() => {
    if (initialize) {
      // axios.get(`${window.conf.bardEndpoint}/words`)
      // .catch(e => console.log(e))
      // .then(res => {
      //   console.log(res.data)
      //   const words = res.data.map(item => item.word_text)
      //   setWords(words)
      // })
      setGame(Object.assign({}, gameConfig))
    }
  }, [initialize])

  return (
      <div>
        <Typography align="center">Pegue as letras na ordem da palavra que aparece na tela.</Typography>
        { initialize ? (
          <>
            <div id="phaser-parent" style={{maxWidth: '450px', margin: 'auto'}}>
              <IonPhaser ref={gameRef} game={game} initialize={initialize} />
            </div>
            <Button onClick={destroy} variant="contained" fullWidth>
              <a href="#1" className="bttn">Reset</a>
            </Button>
          </>
        ) : (
          <>
            <Button variant="contained" fullWidth onClick={() => setInitialize(true)}>
              <a href="#1" className="bttn">Play</a>
            </Button>
          </>
        )}
      </div>
  );
}
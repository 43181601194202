import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: 345,
      position: "relative"
    },
    media: {
      height: 220,
    },
    content: {
        backgroundColor: "#000",
        // position: "absolute",
        bottom: "0",
        padding: "6px",
        overflow: "hidden",
        "&:last-child": {
            paddingBottom: "6px"
        }
    },
    title: {
        fontSize: "1.2em",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: theme.palette.primary.text
    },
    overlay: {
        position: "absolute",
        bottom: "0",
        width: "100%",
        height: "100%",
        // background: "linear-gradient(transparent, rgba(255, 255, 255, 0.9) 70%)"
    }
}));

export default function BookCard(props) {

    const classes = useStyles();

    return (
        <Link to={`/story/read/${props.code}`}>
            <Card className={classes.root}>
                <CardMedia
                    className={classes.media}
                    image={props.cover}
                    title={props.title}
                />
                <div className={classes.overlay}></div>
                <CardContent className={classes.content}>
                    <Typography className={classes.title}>
                        {props.title}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    )
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  emptyBar: {
    width: "100%",
    height: "30px",
    backgroundColor: "#fff",
    cursor: "pointer",
    border: "solid 3px orange",
    borderRadius: "15px",
    // overflow: "hidden"
  },
  fillingBar: props => ({
    width: props.percentage + "%",
    height: "100%",
    backgroundColor: "green",
    borderRadius: "12px",
    position: "relative",
    "&::after": {
      content: "' '",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      display: "block",
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      right: "-20px",
      top: "-10px",
      boxShadow: theme.shadows[4]
    }
  })
}))

export default function ProgressBar(props) {

  const classes = useStyles(props)
  // const theme = useTheme()

  return (
    <>
      <div
      ref={props.reference}
      className={classes.emptyBar}
      onPointerDown={props.onMouseDown}
      onDragStart={() => false}
      style={{touchAction: "none"}}
      >
        <div className={classes.fillingBar}></div>
      </div>
    </>
  )
}

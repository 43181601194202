import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import ProgressBar from './ProgressBar'
import LinearProgress from '@material-ui/core/LinearProgress';

const MediaButton = styled(Button)({
  borderRadius: "50%",
  height: "60px",
  width: "60px"
})

const useStyles = makeStyles((theme) => ({
  root: {
    height: 30,
    borderRadius: 15,
    border: "solid 3px orange",
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}));

export default function AudioPlayer(props) {

  const [elapsed, setElapsed] = useState(0)
  const [isMouseDown, setMouseDown] = useState(false)
  const [isAudioLoaded, setAudioLoaded] = useState(false)
  // const [AudioPlayer, setPlayer] = useState(null)
  const AudioPlayer = useMemo(() => { return new Audio(props.src)}, [])
  const progressBarRef = useRef()

  const Play = useCallback(() => {
    AudioPlayer.play()
  }, [AudioPlayer])

  const Pause = useCallback(() => {
    AudioPlayer.pause()
  }, [AudioPlayer])

  const mouseUp = useCallback((e, el) => {
    const total = el.offsetWidth
    const time = e.clientX - el.offsetLeft
    const duration = AudioPlayer.duration
    const whereClicked = duration * time / total
    AudioPlayer.currentTime = whereClicked
    setMouseDown(false)
    // console.log("mouse up")
  }, [AudioPlayer])

  const mouseDown = useCallback((e) => {
    setMouseDown(true)
    AudioPlayer.pause()
    // console.log("Mouse down")
  }, [AudioPlayer])

  const mouseMove = useCallback((e, el) => {
    const total = el.offsetWidth
    let time = e.clientX - el.offsetLeft >= 0 ? e.clientX - el.offsetLeft : 0
    time = time > el.offsetWidth ? el.offsetWidth : time
    const duration = AudioPlayer.duration
    const whereOver = duration * time / total
    if (isMouseDown) {
      setElapsed(whereOver * 100 / duration)
    }
  }, [AudioPlayer, isMouseDown])

  useEffect(() => {
    if (progressBarRef.current) {
      const onMove = document.onpointermove = (e) => {
        e.preventDefault()
        if (isMouseDown) {
          mouseMove(e, progressBarRef.current)
        }
      }

      const onUp = document.onpointerup = (e) => {
        if (isMouseDown) {
          mouseUp(e, progressBarRef.current)
        }
      }

      return () => {
        document.removeEventListener("pointermove", onMove)
        document.removeEventListener("pointerup", onUp)
      }
    }
  }, [progressBarRef, isMouseDown, mouseMove, mouseUp])

  useEffect(() => {
    setAudioLoaded(false)
    if (!props.src || props.src.length === 0) {
      return
    }
    // setPlayer(new Audio(props.src))
    AudioPlayer.src = props.src
    return () => {
      console.log("Clean")
    }
  }, [props.src])

  useEffect(() => {
    return () => {
      console.log("exiting audio")
      Pause()
    }
  }, [Pause])

  useEffect(() => {
    AudioPlayer.playbackRate = 0.9
    const progressBarListener = AudioPlayer.addEventListener("timeupdate", (e) => {
      const duration = AudioPlayer.duration
      const current = AudioPlayer.currentTime

      if (!isMouseDown) {
        setElapsed(current * 100 / duration)
      } else {
        e.stopImmediatePropagation()
      }
    })

    const canPlayListener = AudioPlayer.addEventListener("canplaythrough", () => {
      setAudioLoaded(true)
      // Play() //autoplay
    })

    return () => {
      AudioPlayer.removeEventListener("timeupdate", progressBarListener)
      AudioPlayer.removeEventListener("canplaythrough", canPlayListener)
      Pause()
    }
  }, [AudioPlayer, isMouseDown, Pause, Play])

  useEffect(() => {
    const duration = AudioPlayer.duration
    const ended = AudioPlayer.ended
    const isPaused = AudioPlayer.paused
    if (props.callback && !ended) {
      // console.log("calling props.callback")
      props.callback({
        duration: duration,
        isPaused: isPaused,
        currentTime: AudioPlayer.currentTime,
        playbackRate: AudioPlayer.playbackRate
      })
    }
  },[AudioPlayer.duration, AudioPlayer.ended, AudioPlayer.paused])

  const classes = useStyles(props)

  return (
    <Box display="flex" alignItems="center" mb={0}>
      <Box mr={2}>
      {
        !AudioPlayer.paused ?
        <MediaButton variant="contained" color="secondary" onClick={Pause}>
          <PauseIcon fontSize="large"/>
        </MediaButton>
        :
        <MediaButton variant="contained" color="primary" onClick={Play}>
          <PlayArrowIcon fontSize="large"/>
        </MediaButton>
      }
      </Box>
      <Box flexGrow={1}>
        {
          isAudioLoaded ?
          <ProgressBar
          reference={progressBarRef}
          onMouseDown={(e) => mouseDown(e)}
          percentage={elapsed}/>
          :
          <LinearProgress className={classes.root} />
        }
      </Box>
    </Box>
  )
}

import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import BookCard from './BookCard'
import axios from 'axios'

export default function Showcase() {

  const [books, setBooks] = useState([])
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    axios.get(`${window.conf.bardEndpoint}/stories`)
    .catch(e => console.log(e))
    .then(res => {
      // console.log(res.data)
      setBooks(res.data)
      setLoaded(true)
    })
  }, [])

  return (
    <>
      <Box mt={1} mb={1}>
        {/* <Typography variant="h5" color="primary">Nível 1</Typography> */}
      </Box>
      
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
      >
        {
        isLoaded ?
        books.map((book, i) => {
          return (
            <Grid item key={i} xs={6} md={2}>
              <BookCard cover={book.cover.url} title={book.title} key={book.id} index={i} code={book.id}/>
            </Grid>
          )
        })
        :
        <Box textAlign="center" mt={4}>
          <Typography variant="h5">Procurando histórias legais para você</Typography>
          <CircularProgress />
        </Box>
        }
      </Grid>
    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ArrowBack from '@material-ui/icons/ArrowBack';
import logo from './logo.jpg'
import SimpleMenu from './menu'

export default function TopBar(props) {

  const path = props.location.pathname
  const title = path === "/" ? null : "Voltar"

  return (
    <AppBar position="static" color="white">
      <Toolbar>
        <Box width="100%" p={1}>
          <Link to="/">
            <Box display="flex" width="100%">
              <img style={{width: "auto", height: "30px"}} src={logo}/>
            </Box>
          </Link>

        </Box>
        {/* <a href="https://papapiu.com.br"><Typography variant="button">Para imprimir</Typography></a> */}
        <SimpleMenu/>
        {/* {
          path === "/"
          ?
          null
          :
          <Box display="flex" onClick={() => props.history.goBack()}>
            <ArrowBack color="primary" />
            <Typography display="inline" color="primary">{ title }</Typography>
          </Box>
        } */}
      </Toolbar>
    </AppBar>
  )
}
import React, { useState, useEffect, useCallback} from 'react'
import { useHistory } from 'react-router-dom'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/styles/makeStyles'
import axios from 'axios'
import { Typography } from '@material-ui/core';
import storage from '../../../firebase'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  },
  image: {
    maxWidth: "100%"
  }
}))

export default function EditBook(props) {
  const [title, setTitle] = useState('')
  const [author, setAuthor] = useState('')
  const [pdf, setPDF] = useState('')
  const [coverName, setCoverName] = useState(null)
  const [cover, setCover] = useState(null)
  const [coverFile, setCoverFile] = useState(null)
  const history = useHistory()

  useEffect(() => {
    console.log(props)
    axios.get(`${window.conf.bardEndpoint}/books/${props.match.params.code}`)
    .then(res => {
      console.log(res)
      setTitle(res.data.title)
      setAuthor(res.data.author)
      setCoverName(res.data.cover)
      setPDF(res.data.file)
    })
  }, [props.match.params])

  const handleCover = useCallback((e) => {
    e.persist()
    const reader =  new FileReader()
    reader.onloadend = () => {
      setCover(reader.result)
      setCoverFile(e.target.files[0])
    }
    reader.readAsDataURL(e.target.files[0])
  }, [])

  const removeImage = async () => {
    // Create a reference to the file to delete
    console.log(coverName)
    var coverRef = storage.refFromURL(coverName);

    // Delete the file
    return await coverRef.delete().then(function() {
      setCover(null)
      setCoverFile(null)
      alert('A capa foi removida')
      return "Success"
    }).catch(function(error) {
      alert('Ocorreu um erro...')
      return error
    });
  }

  const removePDF = async () => {
    // Create a reference to the file to delete
    var pdfRef = storage.refFromURL(pdf);

    // Delete the file
    return await pdfRef.delete().then(function() {
      alert('O PDF foi removido')
      return "Success"
    }).catch(function(error) {
      alert('Ocorreu um erro...')
      return error
    });
  }

  const sendFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      let fileRef = storage.ref().child(file.name)
      fileRef.put(file).then((snapshot) => {
        snapshot.ref.getDownloadURL().then(downloadURL => {
          console.log(downloadURL)
          setCoverName(downloadURL)
          resolve(downloadURL)
        })
      }).catch(err => reject(err))
    })

  }, [])

  const update = useCallback(async () => {
    const coverURL = await sendFile(coverFile)
    if (!coverURL) {
      alert("Ocorreu um erro ao fazer o upload da capa")
      return
    }
    axios.patch(`${window.conf.bardEndpoint}/books/${props.match.params.code}`, {
      title: title,
      author: author,
      cover: coverURL
    }).then(res => {
      if (res.status === 200) {
        alert("Livro atualizado")
      } else {
        alert("Não foi possível atualizar")
        console.log(res)
      }
    })
  }, [title, author, coverFile, coverName])

  const remove = useCallback(async () => {
    const imageRes = await removeImage()
    const pdfRes = await removePDF()

    console.log(imageRes, pdfRes)

    if (!imageRes || !pdfRes) {
      alert("Não foi possível deletar o livro")
    }

    axios.delete(`${window.conf.bardEndpoint}/books/${props.match.params.code}`)
    .then(res => {
      console.log(res)
      if (res.status === 200) {
        alert("Livro deletado")
        history.push('/list/books')
      } else {
        alert("Não foi possível deletar")
        console.log(res)
      }
    })
  }, [props.match.params.code, removeImage, removePDF])

  const classes = useStyles()

  return (
    <Card>
      <CardContent className={classes.root}>
        <TextField label="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)}/>
        <TextField label="Author" variant="outlined" value={author} onChange={(e) => setAuthor(e.target.value)}/>
        <Button variant="contained">
          <label htmlFor="cover">Upload image</label>
        </Button>
        <Button onClick={() => removeImage()}>Remove Image</Button>
        <input type="file" accept="image/*" id="cover" name="cover" hidden onChange={handleCover}/>
        <img className={classes.image} src={cover ? cover : coverName} alt=""/>
        <Typography>PDF: <a href={pdf}>
          {pdf}</a>
        </Typography>
          
        <Button onClick={update} variant="contained" color="secondary" size="large">Enviar</Button>
        <Button onClick={remove} variant="contained" color="secondary" size="large">Deletar</Button>
      </CardContent>
    </Card>
  )
}

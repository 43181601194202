import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import TopBar from './components/topbar/TopBar'
import Showcase from './components/showcase/Showcase'
import BookIndex from './components/lesson/BookIndex'
import PageView from './components/lesson/PageView'
import Reader from './components/read/reader'
import UploadBook from './components/admin/upload/uploadBook.js'
import ListBooks from './components/admin/listBooks'
import EditBook from './components/admin/edit/editBook.js'
import Game from './components/game/game.js'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "./fonts/sassoon.css"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f06292',
      text: '#fff'
    },
    secondary: {
      main: '#66bb6a',
    },
  },
  typography: {
    kidsText: {
      fontFamily: 'Sassoon',
      fontSize: "1.6em"
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "15px"
      },
      containedSecondary: {
        color: "#fff"
      }
    }
  }
});

function App() {

  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Route path="*" component={TopBar}/>
        <Box paddingTop={`${theme.spacing(1)}px`}>
          <Container>
              <Switch>
                <Route exact path="/" component={Showcase}/>
                <Route exact path="/story/:code" component={BookIndex}/>
                <Route exact path="/story/read/:code" component={PageView}/>
                <Route path="/book/read/:code" component={Reader}/>
                <Route path="/upload/book" component={UploadBook}/>
                <Route path="/list/books" component={ListBooks}/>
                <Route path="/edit/book/:code" component={EditBook}/>
                <Route path="/play" component={Game}/>
              </Switch>
          </Container>
        </Box>
      </Router>
    </ThemeProvider>
    </>
  );
}

export default App;

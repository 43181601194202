import React, { useEffect, useState, useCallback } from 'react'
import PaginationBooksTable from './booksTable'
import axios from 'axios'

export default function ListBooks() {
  const [books, setBooks] = useState([])

  useEffect(() => {
    axios.get(`${window.conf.bardEndpoint}/books`)
    .then(res => {
      const data = res.data.data.map(book => {
        return {title: book.title, author: book.author, language: 'null', code: book.code}
      })

      setBooks(data)
    })
  }, [])
  return (
    <div>
      <PaginationBooksTable data={books} type="book"/>
    </div>
  )
}

import * as firebase from 'firebase/app';
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDD9nfAmCd3tbWtIY7uvT-mAFnhLLNEfRo",
  authDomain: "hilda-8988f.firebaseapp.com",
  databaseURL: "https://hilda-8988f.firebaseio.com",
  projectId: "hilda-8988f",
  storageBucket: "hilda-8988f.appspot.com",
  messagingSenderId: "817561457650",
  appId: "1:817561457650:web:aafaacfc09b9f12c4b916b",
  measurementId: "G-VXN9Y03JKM"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics()
const storage = firebase.storage();

export default storage
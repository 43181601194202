import Phaser from 'phaser'
import bk from '../../assets/game_cover.jpg'
import button from '../../assets/play_button.png'

export default class HomeScreen extends Phaser.Scene {

  constructor() {
    super('HomeScreen');
  }

  init () {

  }

  preload () {
    this.load.image('bk', bk);
    this.load.image('button', button);
  }

  create () {
    this.add.image(400, 600, 'bk');
    this.btn = this.add.image(400, 960, 'button');
    this.btn.setInteractive()

    this.input.on('gameobjectdown', this.onObjectClicked, this)

  }

  update (time, delta) {

  }

  onObjectClicked () {
    this.scene.switch('MainScene')
  }
}
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import UseImage from '../hooks/useImage'

import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  image: {
    maxWidth: "100%",
  }
})

export default function BookPage(props) {
  const [image, setImage] = useState("")

  const loadImage = useCallback(
    () => {
      if (props.p.image.length > 0) {
        setImage(`${window.conf.storageEndpoint}/books/${props.code}/images/${props.p.image}`)
      }
    },
    [props.code, props.p.image],
  )

  useEffect(() => {
    loadImage()
  }, [loadImage])

  const classes = useStyles();

  return (
    <Card>
      <CardHeader subheader={`Parágrafo ${props.index + 1}`}/>
      <Box px={1}>
        <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
        >
          {
            image.length === 0 ?
            <Grid item xs={12}>
              <Typography>{props.p.text}</Typography>
            </Grid>
            :
            <>
              <Grid item xs={4}>
                <UseImage src={image}/>
              </Grid>
              <Grid item xs={8}>
                <Typography>{props.p.text}</Typography>
              </Grid>
            </>
          }

        </Grid>
      </Box>
    </Card>

  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    position: 'relative'
    // minHeight: "280px"
  },
  image: {
    maxWidth: "100%",
    maxHeight: "600px",
    display: "block",
    margin: "auto",
    marginBottom: "15px"
  },
  spinner: {
    margin: 'auto',
    width: '100%',
    position: 'absolute',
    marginTop: '20px',
    marginBottom: '20px'
  }
})

export default function UseImage(props) {

  const [loaded, setLoaded] = React.useState(false)

  const handleLoad = () => {
    setLoaded(true)
  }

  const classes = useStyles()
  return (
    <>
    {
      props.isVisible === false ?
      null
      :
      <div className={classes.root}>
        {
          loaded || props.preload === false ?
          null
          :
          <CircularProgress className={classes.spinner} />
        }
        <Fade in={loaded} timeout={1000}>
          <img className={`${classes.image} ${props.className}`} onLoad={handleLoad} src={props.src} alt=""/>
        </Fade>
      </div>

    }
    </>
  )
}

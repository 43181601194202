import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import { v4 as uuidv4 } from 'uuid';
import storage from '../../../firebase'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: "20px",
    padding: "10px",
    '& .MuiTextField-root': {
      margin: theme.spacing(1) + "px 0px",
      width: '100%'
    },
    // boxShadow: (checked) => checked ? `${theme.palette.success.main} 0px 0px 3px 3px` : 'none',
  },
  button: {
    marginRight: theme.spacing(2)
  },
  typo: {
    marginTop: theme.spacing(2)
  },
  select: {
    width: "200px",
    marginRight: theme.spacing(2),
    '& .MuiInputBase-input': {
      padding: theme.spacing(1)
    }
  },
  languageLabel: {
    marginTop: theme.spacing(1)
  }
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export default function UploadBook() {
  const [title, setTitle] = useState(null)
  const [author, setAuthor] = useState(null)
  const [cover, setCover] = useState(null)
  const [pdf, setPdf] = useState(null)
  const [pdfFile, setPdfFile] = useState(null)
  const [coverFile, setCoverFile] = useState(null)
  const [coverURL, setCoverURL] = useState(null)
  const [pdfURL, setPdfURL] = useState(null)
  const [language, setLanguage] = useState('pt')
  // const [formDataArray, setFormDataArray] = useState([])
  const history = useHistory()
  const code = uuidv4()

  // const pushFile = useCallback((fileItem) => {

  //   const existsIndex = formDataArray.findIndex(el => el.name === fileItem.name)
  //   if (existsIndex !== -1) {
  //     let newArray = [...formDataArray]
  //     newArray[existsIndex] = fileItem
  //     setFormDataArray(newArray)
  //   } else {
  //     setFormDataArray([...formDataArray, fileItem])
  //   }
  // }, [formDataArray])

  const handleCover = useCallback((e) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      setCover(reader.result)
    }
    reader.readAsDataURL(e.target.files[0])
    // const coverFile = {type: 'images/', file: e.target.files[0], index: 'cover'}
    // pushFile(e.target.files[0])
    setCoverFile(e.target.files[0])
  }, [])

  const handlePDF = useCallback((e) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      setPdf(reader.result)
    }
    reader.readAsDataURL(e.target.files[0])
    // setPdfName(e.target.files[0].name)
    // const pdfFile = {type: 'pdf/', file: e.target.files[0], index: 'pdf'}
    setPdfFile(e.target.files[0])
  }, [])

  const sendFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      let fileRef = storage.ref().child(file.name)
      fileRef.put(file).then((snapshot) => {
        snapshot.ref.getDownloadURL().then(downloadURL => {
          console.log(downloadURL)
          setCoverURL(downloadURL)
          resolve(downloadURL)
        })
      }).catch(err => reject(err))
    })

  }, [])

  const registerBookInfo = useCallback(async (book) => {
    return await axios.post(`${window.conf.bardEndpoint}/books`, book)
    .catch(e => e)
    .then((res) => res)
  }, [])

  const getBookInfo = useCallback((cover, pdf) => {

    if (!title || !coverFile) {
      alert("Title and cover are necessary.")
      return
    }

    const book = {
      title: title,
      author: author,
      code: code,
      cover: cover,
      file: pdf,
      language: language
    }

    console.log(book)

    return book
  }, [title, author, code, language])

  const handleLanguage = (e) => {
    setLanguage(e.target.value)
  }

  const handleSubmit = useCallback(async () => {
    const coverURL = await sendFile(coverFile)
    const pdfURL = await sendFile(pdfFile)
    console.log(coverURL, pdfURL)
    if (coverURL && pdfURL) {
      const book = getBookInfo(coverURL, pdfURL)
      registerBookInfo(book).then(res => {
        if (res.status === 201) {
          alert("Livro cadastrado com sucesso!")
          history.push('/list/books')
        }
      })
    }
  }, [sendFile, getBookInfo, code, coverFile, pdfFile])

  const classes = useStyles();

  const languages = [
    {title: 'Inglês', value: 'en'},
    {title: 'Italiano', value: 'it'},
    {title: 'Francês', value: 'fr'},
    {title: 'Português', value: 'pt'},
  ]

  return (
    <div>
      <Typography gutterBottom variant="h5">Livro</Typography>
      <Paper className={classes.paper}>
        <TextField label="Title" variant="outlined" onChange={(e) => setTitle(e.target.value)}/>
        <TextField label="Author" variant="outlined" onChange={(e) => setAuthor(e.target.value)}/>
        <InputLabel className={classes.languageLabel} id="language">Lingua</InputLabel>
        <Select
        className={classes.select}
        onChange={handleLanguage}
        labelId="language"
        value={language}
        input={<BootstrapInput />}
        >
          {
            languages.map(lang => <MenuItem value={lang.value}>{lang.title}</MenuItem>)
          }
        </Select>
        <Button variant="contained" className={classes.button}>
          <label htmlFor="cover">Upload image</label>
        </Button>
        <Button variant="contained">
          <label htmlFor="pdfFile">Upload PDF</label>
        </Button>
        <input type="file" accept="image/*" id="cover" name="cover" hidden onChange={handleCover}/>
        <img src={cover} alt=""/>
        <input type="file" accept="application/pdf" id="pdfFile" name="pdfFile" hidden onChange={handlePDF}/>
        <Typography className={classes.typo}>PDF: {pdfFile ? pdfFile.name : "Envie um arquivo..."}</Typography>
      </Paper>

      <Box display="flex" justifyContent="space-between" mb={2} width="100%">
        <Button color="primary" variant="contained" size="large" onClick={handleSubmit}><DoneIcon/> enviar</Button>
        {/* <Fab color="secondary" variant="extended" aria-label="add" onClick={addRow}><AddIcon/> Parágrafo</Fab> */}
      </Box>

    </div>
  )
}

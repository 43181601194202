import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
// import PdfViewer from '../custom/pdfReader'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import downloader from '../custom/downloader'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: `0px ${theme.spacing(2)}px ${theme.spacing(1)}px 0px`,
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1)
    }
  }
}))

export default function Reader(props) {

  const [pdf, setPDF] = useState(null)
  const history = useHistory()

  useEffect(() => {
    axios.get(`${window.conf.bardEndpoint}/books/${props.match.params.code}`)
    .then(res => {
      console.log(res)
      setPDF(res.data.file)
    })
  }, [])

  const download = () => {
    downloader(`${pdf}`)
  }

  // const goToView = () => {
  //   history.push(`https://docs.google.com/viewer?url=${pdf}&embedded=true`)
  // }

  const classes = useStyles();

  return (
    <>
      <Button className={classes.button} variant="contained" color="primary" onClick={download}>
        <GetAppIcon/>
        Baixar
      </Button>
      <a target="_blank" href={`https://docs.google.com/viewer?url=${window.conf.storageEndpoint}/books/${props.match.params.code}/pdf/${pdf}&embedded=true`}>
        <Button className={classes.button} variant="contained">
          <OpenInNewIcon/>
          Abrir
        </Button>
      </a>

      {/* <PdfViewer url={pdf ? pdf : null}/> */}
    </>
  )
}

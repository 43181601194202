import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
// import { borders } from '@material-ui/system';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import VisibilityIcon from '@material-ui/icons/Visibility';
import AudioPlayer from '../player/AudioPlayer'
import UseImage from '../hooks/useImage'
import Fade from '@material-ui/core/Fade'
import axios from 'axios'

const useStyles = makeStyles(theme => ({
  image: {
    // maxWidth: "100%",
    maxWidth: "450px",
    display: "block",
    margin: "auto"
  },
  kidsText: {
    fontFamily: theme.typography.kidsText.fontFamily,
    fontSize: theme.typography.kidsText.fontSize,
    whiteSpace: "pre-line"
  },
  box: {
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`,
    border: `solid ${theme.palette.primary.light}`,
    // padding: theme.spacing(2)
  }
}))

export default function PageView(props) {
  const [text, setText] = useState("")
  const [translation, setTranslation] = useState("")
  const [isTranslationVisible, setTranslationVisible] = useState(false)
  const [image, setImage] = useState("")
  const [audio, setAudio] = useState("")
  const [words, setWords] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [pagesCount, setPagesCount] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)
  const [isFirstPage, setIsFirstPage] = useState(true)
  const [hasWords, setHasWords] = useState(false)
  const kidsTextRef = useRef()
  const [audioInfo, setAudioInfo] = useState({duration: 0, isPaused: false})
  const [textMarker, setTextMarker] = useState(0)

  const code = props.match.params.code // código do livro
  const index = parseInt(props.match.params.page) - 1 // qual parágrafo

  const pageNumber = parseInt(props.match.params.page)
  const totalpageNumber = parseInt(props.match.params.totalpage)

  const getContent = useCallback(
    () => {
      axios.get(`${window.conf.bardEndpoint}/stories/${code}`)
      .catch(e => console.log(e))
      .then(res => {
        console.log(res.data)
        const page = res.data.pages[currentPage]
        const pagesCount = res.data.pages.length
        const imagePath = page.page_image.url
        setImage(imagePath)
        setPagesCount(pagesCount)
        setAudio(page.narration.url)
        if (page.description) setText(page.description)
        // setTranslation(page.translation)
        if (page.words.length > 0) {
          setHasWords(true)
          setWords(page.words)
        }
      })
    }, [code, index, currentPage]
  )

  useEffect(() => {
    getContent()
  }, [code, getContent])


  const getAudioInfo = useCallback((info) => {
      setAudioInfo(info)
    },[]
  )

  const goNextPage = useCallback(() => {
    if (currentPage === pagesCount - 2) {
      setIsLastPage(true)
    }
    if (currentPage < pagesCount - 1) {
      setCurrentPage(currentPage + 1)
      setIsFirstPage(false)
    }
  }, [currentPage, pagesCount])

  const goBackPage = useCallback(() => {
    if (currentPage !== pagesCount - 2) {
      setIsLastPage(false)
    }

    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
    if (currentPage === 1) {
      setIsFirstPage(true)
    }
  }, [currentPage, pagesCount])

  const classes = useStyles()
  const pathToPlay = props.location.pathname.replace("read", "play")
  const pathToNext = `/book/${code}/read/${pageNumber + 1}/${totalpageNumber}`

  return (
    // <Fade in={isIn} timeout={1000}>
      <Box mt={2}>
        <Paper variant="outlined">
          <Box p={0}>
            {
              <Box minHeight={450} display="flex" alignItems="center" justifyContent="center">
                <UseImage src={image}/>
              </Box>
            }
            <Box p={2}>
              <Box >
                <AudioPlayer src={audio} callback={(info) => getAudioInfo(info)}/>
              </Box>

              <Box mb={2} mt={2}>
                {
                  isLastPage ?
                  <Link to={{pathname: pathToPlay, query: {words: words}}}>
                    <Button variant="contained" size="large" color="secondary" fullWidth={true} >FINISH</Button>
                  </Link>
                  :
                  <Button onClick={goNextPage}  variant="contained" size="large" color="secondary" fullWidth={true} >NEXT</Button>
                }
              </Box>

              <Box mb={2}>
              {
                isFirstPage ?
                null
                :
                <Button onClick={goBackPage}  variant="contained" size="large" color="secondary" fullWidth={true} >BACK</Button>
              }
              </Box>

              {
                text.length > 0?
                <Box mt={2} mb={2} p={1} border={1} borderRadius={16} borderColor="grey.500">
                  <Typography ref={kidsTextRef} align="center" variant="body1" className={classes.kidsText}>{text}</Typography>
                </Box>
                :
                null
              }

              {/* {
                translation ?
                <Box className={classes.box}>
                  <Typography align="center" variant="body1" style={{whiteSpace: 'pre-line', margin: 'auto'}} className={classes.kidsText}>{
                    isTranslationVisible ?
                    translation :
                    null
                  }</Typography>
                  <Button onClick={() => setTranslationVisible(!isTranslationVisible)}><VisibilityIcon/></Button>
                </Box>
                : null
              } */}
              {
                words.map(word => 
                  <Box>
                    <Typography ref={kidsTextRef} align="center" variant="body1" className={classes.kidsText}>{word.word_text}</Typography>
                    <AudioPlayer src={word.audio.url} callback={(info) => getAudioInfo(info)}/>
                  </Box>
                )
              }

              {/* Words flashcard feature - desativado */}
              {/* <Box textAlign="center" mt={2}>
                {
                  hasWords ?
                  <Link to={{pathname: pathToPlay, query: {words: words}}}>
                    <Button variant="contained" size="large" color="secondary">PLAY</Button>
                  </Link>
                  :
                  <Link to={pathToNext}>
                    <Button variant="contained" size="large" color="secondary">NEXT</Button>
                  </Link>
                }
              </Box> */}
            </Box>
          </Box>

        </Paper>
      </Box>
    // </Fade>
  )
}

const splitText = (text) => {
  if (!text) {
    return
  }
  let t = text.split('')
  let elArray = t.map((char, i) => <span key={i}>{char}</span>)
  return elArray
}
import Phaser from 'phaser'
import gobk from '../../assets/gameoverbg.jpg'
import again_button from '../../assets/play_again.png'

export default class GameOver extends Phaser.Scene {

  constructor() {
    super('GameOver');
  }

  init () {

  }

  preload () {
    this.load.image('gobk', gobk);
    this.load.image('again_button', again_button);
  }

  create () {
    this.add.image(400, 600, 'gobk');
    this.btn = this.add.image(400, 510, 'again_button');
    this.btn.setInteractive()

    this.input.on('gameobjectdown', this.onObjectClicked, this)

  }

  update (time, delta) {

  }

  onObjectClicked () {
    this.scene.start('MainScene')
  }
}
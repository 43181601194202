import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
// import { findBook } from '../../books/nv01/01_peterRabbit'
import BookPage from './BookPage'
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { Link } from 'react-router-dom'
import UseImage from '../hooks/useImage'
import axios from 'axios'

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    borderRadius: "30px"
  },
  icon: {
    fontSize: "50px",
    color: "white",
    marginLeft: theme.spacing(1)
  },
  callText: {
    color: "white",
    fontSize: "2.5em"
  },
  cover: {
    border: `solid ${theme.palette.grey[500]} 8px`
  }
}))

export default function BookIndex(props) {

  const [imgSrc, setImageSource] = React.useState(null)
  const [pages, setPages] = React.useState(null)
  const [book, setBook] = React.useState(null)

  const classes = useStyles()

  const code = props.match.params.code;
  // const book = findBook(code)

  useEffect(() => {
    axios.get(`${window.conf.bardEndpoint}/stories/${code}`)
    .then(res => { console.log(res.data); setBook(res.data); setImageSource(`${window.conf.bardEndpoint}${res.data.cover.url}`)})
    // axios.get(`${window.conf.bardEndpoint}/paragraphs?code=${code}`)
    // .then(res => { console.log(res.data); setParagraphs(res.data)})
  }, [code])

  return (
    <div>
      {
        book ?
        <>
          <Box mt={1} mb={2}>
            <Typography variant="h4">{book.title}</Typography>
          </Box>
          <Box minHeight={200} display="flex" alignItems="center" justifyContent="center">
            <UseImage className={classes.cover} src={imgSrc}/>
          </Box>
          <Box mb={3}>
            {/* <Link to={`/book/${code}/read/1/${paragraphs.length}`}> */}
              <Button className={classes.card} fullWidth>
                <Typography variant="h3" className={classes.callText}>Começar</Typography>
                <PlayCircleFilledIcon className={classes.icon}/>
              </Button>
            {/* </Link> */}
          </Box>
          {
            pages ?
            pages.map((p, i) => <BookPage p={p} code={code} key={i} index={i}/>)
            :
            null
          }
        </>
        :
        null
      }

    </div>
  )
}
